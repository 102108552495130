export const TRANSACTION_STATUS = Object.freeze({
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'APPROVED',
});

export const ROLE = Object.freeze({
  USER: 'user',
  ADMIN: 'admin',
});

export const API_ENDPOINTS = {
  SUBMIT_FORM: 'https://submit-form.com/1llHYUdWf',
};

export const BASE_PRICE = 300;