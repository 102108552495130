import React, { useState, useContext } from 'react';
import styles from './Portfolio.module.scss';
import useAuth from '../../hooks/useAuth';
import PortfolioSummary from '../../components/PortfolioSummary';
import useLazyApi from '../../hooks/useLazyApi';
import useApi from '../../hooks/useApi';
import { Button, Result, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';
import { CurrencyContext } from '../../context/CurrencyContext';
import { formatCurrency } from '../../../src/utils/CurrencyFormatter';

const skeletonCards = new Array(3).fill(0);

const Portfolio = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { isInr } = useContext(CurrencyContext);

  const [pnlData, setPnlData] = useState({});
  const [totalPnl, setTotalPnl] = useState(0);
  const [totalPnlPercent, setTotalPnlPercent] = useState(0);

  const [getPNL, { loading: pnlLoading }] = useLazyApi('/metric/pnl', {
    method: 'GET',
  });

  // Fetch portfolio data
  const { loading: portfolioLoading, data: portfolios } = useApi(
    `/portfolio/${auth.user.id}`,
    {},
    {
      onSuccess: async (portfolios) => {
        // console.log('portfolios', portfolios);
        const pnlPromise = portfolios.map((portfolio) =>
          getPNL({
            url: `/metric/pnl/${portfolio.product_id}`,
          }),
        );

        const pnlRes = await Promise.all(pnlPromise);
        setPnlData(pnlRes);

        // Calculate total PnL and percentage
        const totalPnlValue = pnlRes.reduce(
          (sum, pnl) => sum + (pnl.pnl || 0),
          0,
        );
        const totalPnlPercentValue = pnlRes.reduce(
          (sum, pnl) => sum + (pnl.pnlPercent || 0),
          0,
        );

        setTotalPnl(totalPnlValue);
        setTotalPnlPercent(totalPnlPercentValue);
      },
    },
  );

  const navigateTransaction = (transactionType, productId, holdings) => {
    const queryParams = new URLSearchParams();
    queryParams.set('transactionType', transactionType);
    queryParams.set('productId', productId);
    queryParams.set('holdings', holdings);

    const newSearch = `?${queryParams.toString()}`;

    navigate(`/request${newSearch}`);
  };

  return (
    <div>
      <PortfolioSummary />

      <div className={styles.wrapper}>
        {/* <div className={styles.overallPnl}>
          <p>Overall Profit/Loss</p>
          <h3>{formatCurrency(totalPnl, isInr)}</h3>
          <h4>({totalPnlPercent.toFixed(2)}%)</h4>
        </div> */}

        <h2>My Assets</h2>

        {!portfolioLoading && !portfolios?.length && (
          <Result
            title={<div className={styles.noTransaction}>No Assets</div>}
          />
        )}
        {portfolioLoading &&
          skeletonCards.map((_, index) => <Skeleton key={index} />)}

        {(portfolios || [])?.map((portfolio, index) => {
          const pnlInfo = pnlData[index] || {};

          return (
            <div key={portfolio.id} className={styles.card}>
              <div className={styles.cardContent}>
                <div className={styles.cardIcon}>
                  <FontAwesomeIcon
                    size="2xl"
                    icon={faBitcoin}
                    style={{ color: '#956FFD' }}
                  />
                </div>
                <div className={styles.cardInfo}>
                  <div className={styles.cardTitle}>
                    <div className={styles.productTitle}>
                      {portfolio.Product.name}
                    </div>
                    <small className={styles.productTag}>
                      Holdings: {parseInt(portfolio.quantity)}
                    </small>
                    <div className={styles.description}>
                      {portfolio.Product.description}
                    </div>
                  </div>

                  <div className={styles.cardPriceContainer}>
                    <div className={styles.cardPrice}>
                      {formatCurrency(portfolio.Product.buy_price, isInr)}
                    </div>
                    <div className={styles.productPnl}>
                      <h4>Profit/Loss</h4>
                      <div className={styles.pnlInfo}>
                        {pnlLoading ? (
                          <div className={styles.loader} />
                        ) : (
                          <>
                            <p
                              className={
                                pnlInfo?.pnlPercent >= 0
                                  ? styles.positive
                                  : styles.negative
                              }
                            >
                              {formatCurrency(pnlInfo?.pnl, isInr)} (
                              {pnlInfo?.pnlPercent?.toFixed(2)}%)
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.actionArea}>
                <Button
                  className={styles.actionSuccess}
                  block
                  type="primary"
                  onClick={() =>
                    navigateTransaction('buy', portfolio.product_id)
                  }
                >
                  Buy
                </Button>
                <Button
                  type="primary"
                  danger
                  block
                  className={styles.action}
                  onClick={() => {
                    if (portfolio.quantity > 0) {
                      navigateTransaction(
                        'sell',
                        portfolio.product_id,
                        portfolio.quantity,
                      );
                    } else {
                      console.error("You don't have enough holdings to sell.");
                    }
                  }}
                >
                  Sell
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Portfolio;
