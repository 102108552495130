import React, { useContext, useState } from 'react';
import styles from './dashboard.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons/faBitcoin';
import useApi from '../../hooks/useApi';
import { Button, Skeleton } from 'antd';
import 'chartjs-adapter-date-fns';
import LineChart from '../../components/LineChart';
import useLazyApi from '../../hooks/useLazyApi';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PortfolioSummary from '../../components/PortfolioSummary';
import useAuth from '../../hooks/useAuth';
import { CurrencyContext } from '../../context/CurrencyContext';
import { formatCurrency } from '../../../src/utils/CurrencyFormatter';
import { BASE_PRICE } from '../../utils/constant';

const skeletonCards = new Array(3).fill(0);

const Dashboard = () => {
  const [chartData, setChartData] = useState({});
  const navigate = useNavigate();

  const [getPriceHistory] = useLazyApi('/priceHistory/buy');

  const auth = useAuth();

  const { isInr } = useContext(CurrencyContext);

  const { loading: portfolioLoading, data: portfolio } = useApi(
    `/portfolio/${auth.user.id}`,
  );

  const { loading, data: products } = useApi(
    '/products',
    {},
    {
      onSuccess: async (products) => {
        const promises = products.map((product) =>
          getPriceHistory({
            payload: {
              pageSize: 10,
              product_id: product.id,
            },
          }),
        );

        const priceHistory = (await Promise.all(promises)).map(
          (productPrices) =>
            productPrices.map((price) => ({
              date: price.createdAt,
              value: price.price,
            })),
        );
        const priceById = products.reduce((acc, product, index) => {
          acc[product.id] = priceHistory[index];
          return acc;
        }, {});
        setChartData(priceById);
      },
    },
  );

  const navigateTransaction = (transactionType, productId, holdings) => {
    const queryParams = new URLSearchParams();
    queryParams.set('transactionType', transactionType);
    queryParams.set('productId', productId);
    queryParams.set('holdings', holdings);

    const newSearch = `?${queryParams.toString()}`;

    navigate(`/request${newSearch}`);
  };

  const portfolioMap = (portfolio || [])?.reduce((acc, product) => {
    acc[product.product_id] = product;

    return acc;
  }, {});

  return (
    <div className={styles.wrapper}>
      <div className={styles.lightEffect}></div>

      <PortfolioSummary />
      <div className={styles.productWrapper}>
        <div className={styles.productDisplay}>
          {(products || [])?.map((product) => {
            const percent =
              ((product.sell_price - BASE_PRICE) / BASE_PRICE) * 100;
              
            const isLoss = percent < 0;
            return (
              <div className={styles.productItems} key={`display${product.id}`}>
                <h3>{product.name}</h3>
                <h2>{formatCurrency(product.sell_price, isInr)}</h2>
                <div
                  className={`${isLoss && styles.loss}  ${styles.productItemPercentage}`}
                >
                  <FontAwesomeIcon
                    size="l"
                    icon={faCaretUp}
                    className={styles.caret}
                  />
                  <p>{percent.toFixed(2)}%</p>
                </div>
              </div>
            );
          })}

          {/* <div className={styles.productItems}>
            <h3>IBN Large</h3>
            <h2>USDT 300</h2>
            <div className={styles.productItemPercentage}>
              <FontAwesomeIcon
                size="l"
                icon={faCaretUp}
                className={styles.caret}
              />
              <p>2.78%</p>
            </div>
          </div>
          <div className={styles.productItems}>
            <h3>IBN Large</h3>
            <h2>USDT 300</h2>
            <div className={styles.productItemPercentage}>
              <FontAwesomeIcon
                size="l"
                icon={faCaretUp}
                className={styles.caret}
              />
              <p>2.78%</p>
            </div>
          </div> */}
        </div>
        {loading &&
          skeletonCards.map((_, index) => (
            <Skeleton key={index} className={styles.card} />
          ))}

        {(products || [])?.map((product) => {
          const totalReturnPercentage = ((product.buy_price - BASE_PRICE) / BASE_PRICE) * 100;
          const isNegative = totalReturnPercentage < 0;
          const holdings = parseInt(portfolioMap[product.id]?.quantity || 0);

          return (
            <div key={product.id} className={styles.card}>
              <div className={styles.cardContent}>
                <div className={styles.cardIcon}>
                  <FontAwesomeIcon
                    size="2xl"
                    icon={faBitcoin}
                    style={{ color: '#956FFD' }}
                  />
                </div>
                <div className={styles.cardInfo}>
                  <div className={styles.cardTitle}>
                    <div className={styles.productTitle}>{product.name}</div>
                    <small className={styles.productTag}>
                      Holdings:{' '}
                      {portfolioLoading ? (
                        <div className={styles.loader} />
                      ) : (
                        parseInt(portfolioMap[product.id]?.quantity || 0)
                      )}{' '}
                    </small>
                  </div>
                  <div className={styles.cardPriceContainer}>
                    <div className={styles.cardPrice}>
                      {formatCurrency(product.sell_price, isInr)}
                    </div>
                    <div
                      className={`${styles.totalReturn} ${isNegative ? styles.negative : styles.positive}`}
                    >
                      ({totalReturnPercentage.toFixed(2)}%)
                    </div>
                  </div>
                </div>
              </div>
              <div>{product.description}</div>
              <LineChart data={chartData?.[product.id] || []} title="" />
              <div className={styles.actionArea}>
                <Button
                  className={styles.actionSuccess}
                  block
                  type="primary"
                  onClick={() => navigateTransaction('buy', product.id)}
                >
                  Buy
                </Button>
                <Button
                  type="primary"
                  danger
                  block
                  className={styles.action}
                  onClick={() =>
                    navigateTransaction('sell', product.id, holdings)
                  }
                >
                  Sell
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dashboard;
