import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import styles from './Transaction.module.scss';
import useAuth from '../../hooks/useAuth';
import useApi from '../../hooks/useApi';
import { formatIsoDate } from '../../utils/date';
import { Result, Skeleton, Button, Card } from 'antd';
const skeletonCards = new Array(3).fill(0);

const Transaction = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const { loading: orderLoading, data: orders } = useApi(
    `/order/user/${auth.user.id}`,
  );

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const handleBuyClick = () => {
    navigate('/dashboard');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.topNav}>
        <div className={styles.navWrapper}>
          <div className={styles.backIcon} onClick={handleBackClick}>
            <FontAwesomeIcon
              size="2xl"
              icon={faArrowLeft}
              style={{ color: '#ffffff' }}
            />
          </div>
          <div className={styles.heading}>
            <h1>Transcaction History</h1>
          </div>
          <div className={styles.hamburgerIcon}>
            {/* <FontAwesomeIcon
              icon={faBars}
              size="xl"
              className={styles.hamburgerIcon}
              onClick={handleMenuClick}
            /> */}
          </div>
        </div>
      </div>
      {!orderLoading && !orders?.length && (
        <Result
          title={<div className={styles.noTransaction}>No transaction yet</div>}
          extra={
            <Button
              className={styles.buyBtn}
              type="primary"
              onClick={handleBuyClick}
            >
              Buy Now
            </Button>
          }
        />
      )}
      <div className={styles.allTransactionContainer}>
        {orderLoading && (
          <div className={styles.loadingContainer}>
            {skeletonCards.map((_, index) => (
              <Skeleton key={index} />
            ))}
          </div>
        )}

        {orders?.map((order) => (
          <div className={styles.transactionContainer} key={order.id}>
            {/* Add a key for unique identification */}
            <Card className={styles.customCard}>
              <div className={styles.main}>
                <div className={styles.top}>
                  <div className={styles.orderType}>
                    <div
                      className={
                        order.type === 'buy' ? styles.buy : styles.sell
                      }
                    >
                      <h1>{order.type === 'buy' ? 'B' : 'S'}</h1>
                    </div>
                    <h1>
                      {order?.Product?.name}
                      {order.proof && (
                        <a
                          href={order.proof}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faPaperclip} color="#1877f2" />
                        </a>
                      )}
                    </h1>
                  </div>
                  <div className={styles[`${order?.status?.toLowerCase()}`]}>
                    <p>{order?.status?.toLowerCase()}</p>
                  </div>
                </div>
                <div className={styles.bottom}>
                  {order.transaction_id && (
                    <span className={styles.txn}>
                      Txn: {order.transaction_id}
                    </span>
                  )}
                  <h2>
                    {order.type === 'buy' ? 'buy' : 'sell'} {order.quantity} Qty
                    @ {order.total_value} on {formatIsoDate(order.createdAt)}
                  </h2>
                  {order.comment && (
                    <div className={styles.comment}>
                      <p>{order.comment}</p>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Transaction;
